import { skipToken } from "@reduxjs/toolkit/query";
import type { CheckboxProps, FormProps, TableProps } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import type { SearchProps } from "antd/es/input/Search";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDebounce, useList } from "../../../../hooks/util.hooks";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useAddDischargePrescribedDrugsMutation,
  useAddDrugsMutation,
  useGetDrugsByVitalIDQuery,
  useSearchInventoryQuery,
} from "../../../../services/ApiSlice";
import {
  durationList,
  frequencyList,
  noOfCapsulesList,
  toBeTakenList,
  typeOfDrugList,
  unitsList,
} from "../../Prescription/const";

const { Search } = Input;
const { Text, Paragraph, Title } = Typography;

type DrugFormType = {
  currentSelected: IDrug["inventoryID"][];
};

type Prescription = {
  duration?: {
    number: number;
    period: string;
  };
  frequency?: string;
  toBeTaken?: string;
  dosage?: {
    number: number;
    unit: string;
  };
  noOfCapsules?: string;
  typeOfDrug?: string;
  quantity?: number;
  notes?: string;
};

type DrugPrescription = {
  drug: IDrug;
  prescription?: Prescription;
};

const DEBOUNCE_DELAY = 500;

function AddDischargePrescription() {
  const location = useLocation();
  const {id} = useParams();
  console.log('ta',id)
  const [searchText, setSearchText] = useState<string>("");
  const [selectedDrug, setSelectedDrug] = useState<DrugPrescription | null>(
    null
  );

  const searchTextDebounce = useDebounce(searchText, DEBOUNCE_DELAY);
  const Drugs = useSearchInventoryQuery(
    searchTextDebounce && searchTextDebounce.length > 3
      ? { searchText: searchTextDebounce }
      : skipToken
  );
  const [addDrugs, AddDrugs] = useAddDrugsMutation();
  const [addPrescribedDrugs, AddPrescribedDrugs] =
    useAddDischargePrescribedDrugsMutation();

  const GetDrugs = useGetDrugsByVitalIDQuery({
    id: id,
    doctorVisitID: location.state?.doctorVisitID,
  });

  const drugDetails = [{ drug: GetDrugs.data?.[0] }];
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [selectedDrugs, selectedDrugsActions] = useList<DrugPrescription>();

  const [drugFormInstance] = Form.useForm<DrugFormType>();
  const [prescriptionFormInstance] = Form.useForm<Prescription>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    let prvSelected: IDrug["inventoryID"][] = [];
    if (Array.isArray(Drugs.data)) {
      // Add previous selected prescriptions
      prvSelected = Drugs.data
        .filter(({ inventoryID }) =>
          selectedDrugs.some(({ drug }) => inventoryID === drug.inventoryID)
        )
        .map(({ inventoryID }) => inventoryID);
    }
    drugFormInstance.setFieldValue("currentSelected", prvSelected);
  }, [Drugs.data, selectedDrugs]);

  useEffect(() => {
    if (AddDrugs.isSuccess || AddPrescribedDrugs.isSuccess) {
      onCancel();
      notification.success({
        message: "Success",
        description: "Successfully Added Prescription",
      });
    } else if (AddDrugs.isError || AddPrescribedDrugs.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [AddDrugs, AddPrescribedDrugs]);

  const allowSubmit = useMemo(
    () =>
      selectedDrugs.length &&
      selectedDrugs.every(({ prescription }) => !!prescription),
    [selectedDrugs]
  );

  const currentSelected = Form.useWatch("currentSelected", drugFormInstance);

  // useEffect(() => {
  //   if (Array.isArray(Drugs.data) && currentSelected.length) {
  //     // Get selected Drugs data
  //     const newSelected: DrugPrescription[] = [];

  //     for (const inventoryID_ of currentSelected) {
  //       // check if already added
  //       if (
  //         !selectedDrugs.some(
  //           ({ drug: { inventoryID } }) => inventoryID === inventoryID_
  //         )
  //       ) {
  //         const drug = Drugs.data?.find(
  //           ({ inventoryID }) => inventoryID === inventoryID
  //         );
  //         if (drug) newSelected.push({ drug });
  //       }
  //     }

  //     // Add to 'selectedDrugs'
  //     selectedDrugsActions.append(newSelected);
  //   }
  // }, [currentSelected]);

  const onAddDrugs: FormProps<DrugFormType>["onFinish"] = (values) => {
    const { currentSelected } = values;

    if (Array.isArray(Drugs.data) && currentSelected.length) {
      // Get selected Drugs data
      const newSelected: DrugPrescription[] = [];

      for (const inventoryID_ of currentSelected) {
        // check if already added
        if (
          !selectedDrugs.some(
            ({ drug: { inventoryID } }) => inventoryID === inventoryID_
          )
        ) {
          const drug = Drugs.data?.find(
            ({ inventoryID }) => inventoryID === inventoryID_
          );
          if (drug) newSelected.push({ drug });
        }
      }

      // Add to 'selectedDrugs'
      selectedDrugsActions.append(newSelected);
    }
  };

  const onDrugRemove = (inventoryID_: IDrug["inventoryID"]) => {
    if (inventoryID_) {
      // Remove from 'selectedPrescriptions'
      selectedDrugsActions.filter(
        ({ drug: { inventoryID } }) => inventoryID !== inventoryID_
      );
    }
  };

  const onAddPrescription = async () => {
    if (selectedDrug) {
      try {
        const values = await prescriptionFormInstance.validateFields();
        const index = selectedDrugs.findIndex(
          ({ drug: { inventoryID } }) =>
            inventoryID === selectedDrug?.drug.inventoryID
        );
        selectedDrugsActions.update(index, {
          drug: selectedDrug.drug,
          prescription: values,
        });

        prescriptionFormInstance.resetFields();
        setSelectedDrug(null);
      } catch (error) {}
    }
  };

  const onCancel = () => {
    // const { from, returnState } = location.state;
    const from = location.state?.from;
    const returnState = location.state?.returnState;
    navigate(from ?? -1, { state: returnState ?? {} });
  };

  const onModalClose = () => {
    prescriptionFormInstance.resetFields();
    setSelectedDrug(null);
  };

  const onEditPrescription = (drug: DrugPrescription) => {
    setSelectedDrug(drug);
    if (drug?.prescription)
      prescriptionFormInstance.setFieldsValue(drug.prescription);
  };

  const onSubmit = () => {
    // const { TCOPID, TCIPID, patientID, doctorVisitID } = location.state;
    const TCOPID = location.state?.TCOPID;
    const TCIPID = id;
    const patientID = location.state?.patientID;
    const doctorVisitID = location.state?.doctorVisitID;
    console.log('tcip',TCIPID)
    const payload: IAddPatientDrugPayload[] = [];
    selectedDrugs.forEach(({ drug, prescription }) => {
      payload.push({
        mrp: drug.mrp,
        discount: drug.discount,
        discountValue: drug.discVal,
        pharmaSource: drug.pharmaSource,
        particulars: drug.particulars,
        pack: drug.pack,
        inventoryID: drug.inventoryID,
        doctorVisitID,
        patientDrugID: null,
        categoryID: TCIPID ? 139 : 140,
        categoryRefID: TCIPID ?? TCOPID,
        prescribedBy: user?.himsEmployeeID ?? "",
        locationID: 1,
        createdBy: user?.himsEmployeeID ?? "",
        modifiedBy: user?.himsEmployeeID ?? "",
        qty: prescription?.quantity ?? 0,
        drugNote: prescription?.notes ?? "",
        dosage: `${prescription?.dosage?.number} ${prescription?.dosage?.unit}`,
        timeofDay: null,
        timeToTake: prescription?.toBeTaken?.toString() ?? "",
        days: `${prescription?.duration?.number} ${prescription?.duration?.period}`,
        typeOfDrug: prescription?.typeOfDrug ?? "",
        noOfCapsules: prescription?.noOfCapsules ?? "",
        indent_Status: ""
      });
    });

    addPrescribedDrugs(payload);

    // TODO: need to verify
    // if (location.state?.isPrescribedDrug) addPrescribedDrugs(payload);
    // else addDrugs(payload);
  };

  const columns: TableProps<DrugPrescription>["columns"] = [
    {
      title: "Sl. No.",
      key: "slNo",
      render: (_value, _record, index) => index + 1,
    },
    {
      title: "Drug Name",
      dataIndex: ["drug", "particulars"],
    },

    {
      title: "Price",
      dataIndex: ["drug", "mrp"],
      render: (price) => (
        <Paragraph>
          <Text type="secondary">₹</Text>{" "}
          <Text strong>{parseFloat(price).toFixed(2)}</Text>
          <Text type="secondary">/-</Text>
        </Paragraph>
      ),
    },
    {
      title: "Dosage",
      key: "dosage",
      render: (_, record) =>
        record?.prescription ? (
          <Button
            onClick={() => onEditPrescription(record)}
            type="link"
            style={{ padding: 0 }}
          >
            Edit Dosage
          </Button>
        ) : (
          <Button
            onClick={() => setSelectedDrug(record)}
            type="link"
            style={{ padding: 0 }}
          >
            Add Dosage
          </Button>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_v, record) => (
        <Button
          type="text"
          danger
          onClick={() => onDrugRemove(record.drug.inventoryID)}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Flex vertical gap={24}>
          <Title>Add Prescription</Title>

          <Spin
            spinning={
              AddDrugs.isLoading ||
              AddPrescribedDrugs.isLoading ||
              GetDrugs.isFetching
            }
            fullscreen
          />

          <Card>
            <Form
              layout="vertical"
              form={drugFormInstance}
              onFinish={onAddDrugs}
            >
              <Row gutter={[24, 24]} align="middle">
                <Col>
                  <Title level={4}>Search Medicines</Title>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Search"
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                  />
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="currentSelected"
                    label="Search Results"
                    hidden={!Drugs.data?.length}
                  >
                    <CheckboxList
                      loading={Drugs.isFetching}
                      list={Drugs.data ?? []}
                      labelKey="particulars"
                      valueKey="inventoryID"
                      disabledList={selectedDrugs.map(
                        ({ drug: { inventoryID } }) => inventoryID
                      )}
                    />
                  </Form.Item>
                </Col>
                {Drugs.data?.length ? (
                  <>
                    <Col flex="auto"></Col>
                    <Col>
                      {/* TODO: remove Add button and Auto add when clicked checkbox */}
                      <Button
                        htmlType="submit"
                        type="primary"
                        disabled={!currentSelected.length}
                      >
                        Add To List
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Form>
          </Card>
          {selectedDrugs?.length ? (
            <Table
              columns={columns}
              dataSource={selectedDrugs}
              pagination={selectedDrugs.length > 10 ? { pageSize: 10 } : false}
              rowKey="inventoryID"
              scroll={{ x: true }}
            />
          ) : null}

          {selectedDrugs?.length ? (
            <Row justify="end" gutter={24}>
              <Col>
                <Button onClick={onCancel}>Cancel</Button>
              </Col>
              <Col>
                <Button
                  disabled={!allowSubmit}
                  type="primary"
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </Col>
            </Row>
          ) : null}
        </Flex>

        <Modal
          open={!!selectedDrug}
          width="70vw"
          title="Add or Update Dosage"
          onCancel={onModalClose}
          zIndex={5000}
          onOk={onAddPrescription}
          okText="Save"
        >
          <Form form={prescriptionFormInstance} layout="vertical">
            <Title level={4}>{selectedDrug?.drug?.particulars}</Title>

            <Row gutter={[24, 16]}>
              <Col>
                <Space.Compact>
                  <Form.Item
                    name={["duration", "number"]}
                    initialValue={1}
                    label="Duration"
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item
                    name={["duration", "period"]}
                    initialValue="Day(s)"
                    label=" "
                  >
                    <Select options={durationList} />
                  </Form.Item>
                </Space.Compact>
              </Col>

              <Col span={4}>
                <Form.Item
                  name="frequency"
                  initialValue="Daily"
                  label="Frequency"
                >
                  <Select options={frequencyList} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  name="toBeTaken"
                  initialValue="After food"
                  label="To Be Taken"
                >
                  <Select options={toBeTakenList} />
                </Form.Item>
              </Col>

              <Col>
                <Space.Compact>
                  <Form.Item
                    name={["dosage", "number"]}
                    initialValue={1}
                    label="Dosage"
                  >
                    <InputNumber min="0" max="1000" step="50" />
                  </Form.Item>
                  <Form.Item
                    name={["dosage", "unit"]}
                    initialValue="mg"
                    label=" "
                  >
                    <Select options={unitsList} />
                  </Form.Item>
                </Space.Compact>
              </Col>

              <Col span={4}>
                <Form.Item
                  name="noOfCapsules"
                  initialValue="1"
                  label="No Of Capsules"
                >
                  <Select options={noOfCapsulesList} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="quantity" initialValue={1} label="Quantity">
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  name="typeOfDrug"
                  initialValue="Oral"
                  label="Type Of Drug"
                >
                  <Select options={typeOfDrugList} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="notes" label="Drug Note Instructions">
                  <Input.TextArea
                    maxLength={500}
                    rows={1}
                    showCount
                    placeholder="Drug Note Instructions"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default AddDischargePrescription;

const generatePayload = (values: Prescription, commonValues: any) => {};

// TODO: move to Components

type Props<T> = {
  list: T[];
  onChange?: (checkedValues: any[]) => void;
  value?: any[];
  labelKey: keyof T;
  valueKey: keyof T;
  disabledList: any[];
  loading: boolean;
  pageSize?: number;
  showSearch?: boolean;
};

function CheckboxList<T>({
  list,
  onChange: onChangeExt,
  value: valueExt,
  labelKey,
  valueKey,
  disabledList,
  loading,
  pageSize = 10,
  showSearch = false,
}: Props<T>) {
  const [filteredList, setFilteredList] = useState<T[]>(list);
  const [value, setValue] = useState<T[keyof T][]>(valueExt ?? []);

  useEffect(() => {
    if (onChangeExt) onChangeExt(value);
  }, [value]);

  useEffect(() => {
    if (Array.isArray(valueExt)) setValue(valueExt);
  }, [valueExt]);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const onSearch: SearchProps["onSearch"] = (value) =>
    setFilteredList(
      list.filter((item) =>
        String(item[labelKey]).toLowerCase().includes(value.toLocaleLowerCase())
      )
    );

  const onCheck = (value: T[keyof T]) => {
    setValue((prv) => [...prv, value]);
  };
  const onUncheck = (value: T[keyof T]) => {
    setValue((prv) => prv.filter((value_) => value_ !== value));
  };

  return (
    <List
      loading={loading}
      pagination={
        filteredList.length > pageSize ? { pageSize: pageSize } : false
      }
      style={{ width: "100%" }}
      header={
        showSearch ? (
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{ width: 200 }}
            enterButton="Search"
          />
        ) : undefined
      }
      grid={{ gutter: 16, column: 2 }}
      dataSource={filteredList}
      renderItem={(item) => {
        const value = item[valueKey];

        const onChange: CheckboxProps["onChange"] = (e) => {
          if (e.target.checked) onCheck(value);
          else onUncheck(value);
        };

        return (
          <List.Item>
            <Checkbox
              checked={valueExt?.includes(value)}
              onChange={onChange}
              value={value}
              disabled={disabledList?.includes(value)}
            >
              {item[labelKey] as string}
            </Checkbox>
          </List.Item>
        );
      }}
    />
  );
}
