import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useGetDischargeSummaryQuery,
  useGetDoctorVisitByvisitIDQuery,
  useGetOPSummaryQuery,
  useGetVisitTypesQuery,
  usePostDoctorVisitNotesMutation,
  usePostOutPatientMutation,
} from "../../../../services/ApiSlice";

const { TextArea } = Input;
const { confirm } = Modal;
const { Text, Paragraph, Title } = Typography;

function VisitNotes() {
  const { TCIPID } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const isRoleBasedVisible = useRoleBasedVisible();

  const location = useLocation();
  const DoctorVisitNotes = useGetDoctorVisitByvisitIDQuery(
    location.state?.doctorVisitID ?? skipToken
  );

  const OPSummary = useGetOPSummaryQuery(TCIPID ?? skipToken);
  const PatientData = useGetDischargeSummaryQuery(TCIPID ?? skipToken);
  const VisitTypeData = useGetVisitTypesQuery("VisitType");
  const [postOPSummary, PostOPSummary] = usePostOutPatientMutation();
  const [postDoctorVisitNotes, PostDoctorVisitNotes] =
    usePostDoctorVisitNotesMutation();

  const [formInstance] = Form.useForm();

  const visitType = useMemo(
    () =>
      Array.isArray(VisitTypeData.data)
        ? VisitTypeData.data.map((item) => ({
            value: item?.dropDownSetID,
            label: item.dropDownValue,
          }))
        : [],
    [VisitTypeData]
  );

  const personalDetails: any = PatientData
    ? [
        {
          UHID: PatientData?.data?.uhid,
          Name: PatientData?.data?.patientName,
          MobileNo: PatientData?.data?.phoneNo,
          TCIPID: PatientData?.data?.tcipid,
        },
      ]
    : [];
  const admissionDetails: any = PatientData
    ? [
        {
          AddmissionDate: FormatDateTime(PatientData?.data?.admissionDate),
          RoomNo: PatientData?.data?.roomNo,
          BedNo: PatientData?.data?.bedNo,
          Location: PatientData?.data?.location,
        },
      ]
    : [];

  useEffect(() => {
    if (PostDoctorVisitNotes.isSuccess) {
      notification["success"]({
        message: "Successfully saved",
        icon: (
          <i className="las la-check-double" style={{ color: "green" }}></i>
        ),
      });
      formInstance.resetFields();
      navigate(`/inpatient-details/${TCIPID}`, { state: { activeKey: "2" } });
      //  else {
      //   notification["warning"]({
      //     message: "Service Failure",
      //     icon: (
      //       <i
      //         className="las la-exclamation-circle"
      //         style={{ color: "red" }}
      //       ></i>
      //     ),
      //   });
      // }
    }
    if (PostDoctorVisitNotes.isError) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [PostDoctorVisitNotes]);

  //   useEffect(() => {
  //     if (OPSummary.data) {
  //       const values = {
  //         chiefCompliants: OPSummary?.data?.chiefCompliants,
  //         provisionalDiagnosis: OPSummary?.data?.provisionalDiagnosis,
  //         pastMedicalHistory: OPSummary?.data?.pastMedicalHistory,
  //         investigations: OPSummary?.data?.investigations,
  //         examination: OPSummary?.data?.examination,
  //       };
  //       formInstance.setFieldsValue(values);
  //     }
  //   }, [OPSummary.data]);

  useEffect(() => {
    if (DoctorVisitNotes.data) {
      formInstance.setFieldsValue({ ...DoctorVisitNotes?.data });
    }
  }, [DoctorVisitNotes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fields = formInstance.getFieldsValue();
    const fieldsLength = Object.keys(fields).length;
  }, [formInstance]);

  const handleSubmit = (values: any) => {
    try {
      postDoctorVisitNotes({
        ...values,
        tcipid: TCIPID,
        doctorID: user?.himsEmployeeID,
      });
    } catch (error) {
      console.error("Failed to post data", error);
    }
  };

  const onSubmit = async (isDraft: boolean) => {
    confirm({
      title: "Are you sure to save ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const values = await formInstance.validateFields();
          postOPSummary({ ...values, TCOPID: TCIPID ?? "", isDraft: isDraft }); // TODO: check key
        } catch (error) {}
      },
    });
  };

  const handleBack = () => {
    // const feilds = formInstance.getFieldsValue;
    // const feildsLength =Object.keys(feilds).length;
    // console.log("length",feildsLength);
    if (DoctorVisitNotes.data) {
      navigate(`/inpatient-details/${TCIPID}`, { state: { activeKey: "2" } });
    } else {
      confirm({
        title: "Are you sure to cancel ?",
        centered: true,
        okText: "Yes",
        cancelText: "No",
        onOk: async () => {
          formInstance.resetFields();
          navigate(`/inpatient-details/${TCIPID}`, {
            state: { activeKey: "2" },
          });
        },
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <Flex vertical gap={24}>
            <Spin spinning={DoctorVisitNotes.isFetching} fullscreen />
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">IP Doctor Visit</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="hyperlink"
                        to={`/inpatient-details/${TCIPID}`} // TODO: add State to move to dactor tab
                      >
                        Admission Details
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">IP Doctor Visit</li>
                  </ul>
                </div>
              </div>
            </div>
            <Card>
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">Patient Information </h3>
                      <ul className="personal-info">
                        <div className="text-center">
                          {/* <Spin spinning={isPatientLoading} /> */}
                        </div>

                        {Array.isArray(personalDetails) ? (
                          personalDetails.map((item: any, index: any) => (
                            <ListItem
                              key={index}
                              data={item}
                              isLoading={PatientData.isFetching}
                            />
                          ))
                        ) : (
                          <ListItem
                            data={personalDetails}
                            isLoading={PatientData.isFetching}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {isRoleBasedVisible([
                  Role.Mod,
                  Role.Admin,
                  Role.Nurse,
                  Role.Doctor,
                ]) && (
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Addmission Information </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatinetListLoading} /> */}
                          </div>
                          {Array.isArray(admissionDetails) ? (
                            admissionDetails.map((item: any, index: any) => (
                              <ListItem
                                key={index}
                                data={item}
                                isLoading={PatientData.isFetching}
                              />
                            ))
                          ) : (
                            <ListItem
                              data={admissionDetails}
                              isLoading={PatientData.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
            <Card>
              <Spin spinning={PostDoctorVisitNotes.isLoading} fullscreen />{" "}
              <Form
                layout="vertical"
                form={formInstance}
                onFinish={handleSubmit}
                disabled={DoctorVisitNotes.data?.doctorVisitID}
                // disabled={
                //   OPSummary.isFetching || PostOPSummary.isLoading || !isEditable
                // }
              >
                <Form.Item
                  name="VisitType"
                  label="Visit Purpose"
                  style={{ width: 300 }}
                  rules={[{ required: true, message: "Please Enter Room No" }]}
                >
                  <Select
                    placeholder="Select Type"
                    style={{ width: "100%" }}
                    options={visitType}
                    loading={VisitTypeData.isFetching}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
                <Form.Item
                  name="visitNotes"
                  label="Notes"
                  rules={[
                    {
                      message: "Please Enter Notes",
                      required: true,
                    },
                  ]}
                >
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
                <Form.Item name="diagnosis" label="Diagnosis">
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
                <Form.Item name="clinicalSummary" label="Clinical Summary">
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
                <Form.Item name="investigationDone" label="Investigations Done">
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
                <Form.Item
                  name="treatmentGivenAtHospital"
                  label="Treatments Given"
                >
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
                <Form.Item
                  name="progressInHospital"
                  label="Treatments In Progress"
                >
                  <TextArea
                    showCount
                    placeholder="Please Enter Comments"
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>

                <Space direction="horizontal" style={{ float: "right" }}>
                  <Button
                    style={{ float: "right" }}
                    type="primary"
                    onClick={handleBack}
                    disabled={false}
                  >
                    Cancel
                  </Button>
                  {!DoctorVisitNotes.data && (
                    <Button
                      style={{ float: "right" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  )}
                </Space>
              </Form>
            </Card>

            {/* <Row justify="end" gutter={24}>
              <Col>
                <Button>Cancel</Button>
              </Col>
              <Col>
                <Button disabled={!selectedTests.length} type="primary">
                  Submit
                </Button>
              </Col>
            </Row> */}
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default VisitNotes;

export const ListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({ data, isLoading = false }) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <li>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            {isLoading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 300 }}
              />
            ) : (
              <div className="text">{value}</div>
            )}
          </li>
        ))}
      </div>
    </>
  );
};
